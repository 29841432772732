import { createApp } from 'vue'
import App from './App.vue'
import {createRouter, createWebHistory} from 'vue-router'  //createWebHashHistory
import { routes } from './routes'

const router = createRouter({
    history: createWebHistory(),
    routes,
    mode: 'history'
});
const app = createApp(App);
app.use(router);
app.directive('click-outside', {
    mounted(el, binding) { //vnode
        el.clickOutsideEvent = function(event) {
            if (!(el === event.target || el.contains(event.target))) {
                binding.value(event, el);
            }
        };
        document.body.addEventListener('click', el.clickOutsideEvent);
    },
    unmounted(el) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
    }
});
app.mount('#app'); 
