<template>
  <router-view ref="header" name="header"></router-view>
  <router-view></router-view>
  <router-view ref="footer" name="footer"></router-view>

  <alert-popup v-if="ll" :adata="adata" ref="alertdiv"></alert-popup>
</template>

<script>
    /* eslint-disable */
import axios from 'axios'
import AlertPopup from './components/AlertPopup'
//import { ref, onMounted } from 'vue'

export default {
  name: 'App',
  components: {
      AlertPopup
  },
  data: function() {
      return{
          ll:null,
          langObj:null,
          languages:null,
          lang: window.mlang||'en',
          downloadLink: null,
          downloadName: 'myprintscreen.exe', 
          user:{
              country:null,
              country_code:null,
              phone_code:null
          },
          adata:{
              show:false,
              popup:1
          }
      }
  },
  methods: {
      async init(){
          let lang = this.getCookie('lang');
          if(lang){
              lang = lang.substr(0,2).toLowerCase();
              this.lang = lang;
          }
          await this.getll(true);
          if(this.ll && this.ll.i10143) document.title = this.ll.i10143;
          if(this.ll && this.ll.i10144) document.querySelector('meta[name="description"]').setAttribute("content", this.ll.i10144);
          this.downloadLink = window.mdomain?window.location.protocol+'//'+window.mdomain+'/api/main/soft/myprintscreen.exe' :'https://myprintscreen.com/api/main/soft/myprintscreen.exe';
      },
      showQuestion(val){
          val = parseInt(val);
          if(typeof this.question['a'+val] !== 'undefined') this.question['a'+val] = !this.question['a'+val];
      },
      async getll(listLang){
          let res = await axios.post('/api/main/get', {datall:{lang: this.lang},listLang:listLang});
          if(res && res.data){
              if(res.data.datall){
                  this.ll = res.data.datall;
                  if(document.getElementsByTagName('title')[0] && document.getElementsByTagName('title')[0].hasAttribute('data-ll')){
                      let title_id = document.getElementsByTagName('title')[0].getAttribute('data-ll');
                      if(title_id && this.ll['i'+title_id]){
                          document.getElementsByTagName('title')[0].text = this.ll['i'+title_id];
                      }
                  }
              }
              if(res.data.languages){
                  let languages = [];
                  for(let jj in res.data.langObj){
                      languages.push(this.langObj[jj].lang);
                  }
                  this.languages = languages;
                  this.langObj = res.data.languages;
              }
          }
      },

      setCookie(name,value,data){
          let expires = "";
          if(data && data.days) {
              let date = new Date();
              date.setTime(date.getTime() + (data.days*24*60*60*1000));
              expires = "; expires=" + date.toUTCString();
          }
          if(data && data.minutes){
              let date = new Date();
              date.setTime(date.getTime() + (data.minutes*60*1000));
              expires = "; expires=" + date.toUTCString();
          }
          document.cookie = name + "=" + (value || "")  + expires + "; path=/; domain=.myprintscreen.com";
      },
      getCookie(name) {
          let nameEQ = name + "=";
          let ca = document.cookie.split(';');
          for(let i=0;i < ca.length;i++) {
              let c = ca[i];
              while (c.charAt(0)==' ') c = c.substring(1,c.length);
              if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
          }
          return null;
      },
      eraseCookie(name) {
          document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      },
      todayF(val){
          let today = new Date();
          if(val) today = new Date(val);
          let dd = String(today.getDate()).padStart(2, '0');
          let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
          let yyyy = today.getFullYear();

          return yyyy + '-' +mm + '-' + dd;
      },
      loadJs(filename, handler){
          let fileref=document.createElement('script');
          fileref.setAttribute("type","text/javascript");
          fileref.setAttribute("src", filename);
          fileref.onreadystatechange=function(){
              if(this.readyState == 'complete'){ handler(); }
          };
          fileref.onload = handler;
          if(typeof fileref!="undefined") document.getElementsByTagName("head")[0].appendChild(fileref);
      },
      clearDomain(domain){
          if(domain && typeof domain==='string'){
              domain = domain.toLowerCase().trim();
              domain = domain.replace(/^https?:\/\//i,'');
              domain = domain.replace(/^www\./,'');
              domain = domain.replace(/\/.*/,'');
              return domain;
          }
          return '';
      },
      validateEmail(email) {
          let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return re.test(String(email).toLowerCase());
      },
      validateUrl(str) {
          let regexp =  /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
          if(regexp.test(str)){return true;}else{return false;}
      },
      validDomain(domain){
          //let regexp = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
          //if(regexp.test(domain)) return true;
          if(domain.indexOf('.')>0) return true;
          return false;
      },
  },
  mounted(){
      this.init();
  }
}
</script>
<style lang="css" src="../public/css/main.css"></style>