<template>
    <div>
        <div v-if="adata && show" class="smlt-modal" :class="[show?'is-visible':'']">
            <div class="smlt-modal-wr">
                <span id="close-btn" class="close-btn close" @click="$root.adata.show=false"></span> <!-- adata -->
                <div class="popup-success-wr">
                    <!--<img v-if="imageSuccess" src="/mse/img/check-circle-solid.svg" alt="Success">
                    <img v-else src="/mse/img/warning.svg" alt="Warning">
                    -->
                    <h4 v-if="title" :class="[imageSuccess?'':'h4red']">{{title}}</h4>
                    <p>{{description}}</p>
                    <!--<span class="close close-text" id="close" @click="show=false">{{ll.i7974||'Close'}}</span>-->
                </div>
            </div>
        </div>
        <div class="overlay" :class="[show?'is-visible':'']" id="overlay"></div>
    </div>
</template>

<script>
    /* eslint-disable */
    export default{
        name: "AlertPopup",
        computed:{
            ll(){
                return this.$root.ll;
            },
            show(){
                return this.adata.show;
            }
        },
        data: function(){
            return {
                title:'',
                description: '',
                //show: false,
                imageSuccess: true
            }
        },
        methods: {
            async init(){
                this.title = this.ll.i10208 || 'Thank you';
                this.description = this.ll.i10209 || 'Your message has been sent successfully. Your personal manager will contact you soon.';
            },
            alert(){
                this.show = true;
            },
            /*confirm(data){
                console.log(data)
            }*/
        },
        mounted(){
            this.init();
        },
        props:{
            adata: {
                type: Object
            }
        },
        /*watch:{
            alertPopup(to){
                if(to){
                    this.$parent.overlayShow = true;
                }
                else{
                    this.$parent.overlayShow = false;
                }
            }
        }*/
    }
</script>

<style scoped lang="css">
    .smlt-modal {
        pointer-events: none;
        opacity: 0;
        transition: opacity 0.3s;
        position: fixed;
        top: 50%;
        left: 0;
        right: 0;
        max-width: 390px;
        margin: 0 auto;
        background: #fff;
        border-radius: 5px;
        transform: translateY(-50%);
        z-index: 99999; }

    .smlt-modal.is-visible{
        opacity: 1;
        pointer-events: unset;
    }

    .smlt-modal-wr {
        position: relative;
        padding: 30px 30px 40px 40px;
    }
    .smlt-modal-wr .close-btn {
        cursor: pointer;
        background-image: url("/public/img/close8.svg");
        background-repeat: no-repeat;
        background-color: #FFFFFF;
        background-size: 50%;
        background-position: center;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.17);
        border-radius: 50%;
        width: 33px;
        height: 33px;
        position: absolute;
        z-index: 999;
        right: -10px;
        top: -10px;
    }
    .popup-success-wr h4{
        font-size: 20px;
    }
    .popup-success-wr p{
        font-size: 16px;
        line-height: 1.5em;
    }

    #overlay {
        pointer-events: none;
        opacity: 0;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5); }
    #overlay.is-visible {
        opacity: 1;
        pointer-events: auto;
        z-index: 9990; }
</style>