/* eslint-disable */
const AppHeader = () => import('./components/Header.vue' );
const AppFooter = () => import('./components/Footer.vue');
const PageScreen = () => import('./components/PageScreen.vue');
const PrivacyPolicy = () => import('./components/PrivacyPolicy.vue');
const TermsService = () => import('./components/TermsService.vue');

const SPage = () => import('./components/SPage.vue');
//const HelloWorld = () => import('./components/HelloWorld.vue');

export const routes = [
    {
        name: 'index',
        path: '/',
        components: {
            header: AppHeader,
            default: PageScreen,
            footer: AppFooter
        }
    },
    {
        name: 'PrivacyPolicy',
        path: '/privacy-policy',
        components: {
            header: AppHeader,
            default: PrivacyPolicy,
            footer: AppFooter
        }
    },
    {
        name: 'TermsService',
        path: '/terms-of-service',
        components: {
            header: AppHeader,
            default: TermsService,
            footer: AppFooter
        }
    },
    {
        name: 'SPage',
        path: '/s/:link/:hash',
        components: {
            header: AppHeader,
            default: SPage,
            footer: AppFooter
        }
    },

    /*
    {name: 'index-es',path: '/es',components: {header:AppHeader,default:Main,footer:AppFooter}},
    {name: 'index-fr',path: '/fr',components: {header:AppHeader,default:Main,footer:AppFooter}},
    {name: 'index-ru',path: '/ru',components: {header:AppHeader,default:Main,footer:AppFooter}},
    {name: 'index-vi',path: '/vi',components: {header:AppHeader,default:Main,footer:AppFooter}},
    */
    {
        path: '/:catchAll(.*)',
        redirect: { name: 'index' }
    }
];
